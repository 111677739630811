<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span>Numbers are not final! It may take up to 24h for daily numbers to update!</span>
      </div>
    </b-alert>

    <b-table
      small
      :items="statsData"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      striped
      foot-clone
    >
      <template #head(datetime)>
        {{ $t('stats.date') }}
      </template>
      <template #head(show)>
        {{ $t('stats.shows') }}
      </template>
      <template #head(click)>
        {{ $t('stats.clicks') }}
      </template>
      <template #head(articles)>
        {{ $t('stats.clicks_article') }}
      </template>
      <template #head(exchange)>
        {{ $t('stats.clicks_exchange') }}
      </template>
      <template #head(advertising)>
        {{ $t('stats.clicks_creative') }}
      </template>
      <template #head(amount)>
        {{ $t('stats.amount') }}
      </template>
      <template #cell(datetime)="row">
        <span v-if="row">
          <a
            href="#"
            :class="row.item._showDetails === true ? 'text-secondary' : ''"
            @click.prevent="showDetails(row)"
          >
            <strong>{{ row.item.datetime }}</strong>
            <feather-icon
              class="ml-15"
              :icon="row.item._showDetails === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              size="20"
            />
          </a>
        </span>
        <span v-else>
          {{ row.item.datetime }}
        </span>
      </template>
      <template #row-details="{ item }">
        <div v-if="item">
          <div
            v-if="loading && !statsDay[item.datetime]"
            class="text-center"
          >
            <b-spinner label="Loading..." />
          </div>

          <b-table
            v-else-if="!loading && statsDay[item.datetime].length"
            small
            :items="statsDay[item.datetime]"
            :fields="fields"
            responsive
            striped
            thead-class="d-none"
            table-variant="primary"
          >
            <template #cell(datetime)="row">
              {{ getFormattedDateTime(row.item.datetime) }}
            </template>

            <template #cell(amount)="data">
              {{ data.value ? currency(accountCurrency, data.value) : 0 }}
            </template>

            <template #cell(show)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
            </template>

            <template #cell(click)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
            </template>

            <template #cell(articles)="data">
              {{ data.item.stats.default.article ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.article) : 0 }}
            </template>

            <template #cell(exchange)="data">
              {{ data.item.stats.default.exchange ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.exchange) : 0 }}
            </template>

            <template #cell(advertising)="data">
              {{ data.item.stats.default.creative ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.creative) : 0 }}
            </template>

            <template #cell(ctr)="data">
              {{ data.item.show ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(data.item.click / data.item.show * 100) : 0 }}%
            </template>

            <template #cell(ecpm)="data">
              {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(data.item.amount / data.item.show * 1000) }}
            </template>
          </b-table>
        </div>
      </template>

      <template #cell(amount)="data">
        {{ data.value ? currency(accountCurrency, data.value) : 0 }}
      </template>

      <template #cell(show)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #cell(click)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #cell(articles)="data">
        {{ data.item.stats.default.article ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.article) : 0 }}
      </template>

      <template #cell(exchange)="data">
        {{ data.item.stats.default.exchange ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.exchange) : 0 }}
      </template>

      <template #cell(advertising)="data">
        {{ data.item.stats.default.creative ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.creative) : 0 }}
      </template>

      <template #cell(ctr)="data">
        {{ data.item.show ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(data.item.click / data.item.show * 100) : 0 }}%
      </template>

      <template #cell(ecpm)="data">
        {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(data.item.amount / data.item.show * 1000) }}
      </template>

      <template #foot(datetime)="data">
        <strong :data-label="data.label">{{ $t('Records') }}: {{ statsData.length }}</strong>
      </template>

      <template #foot(click)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('click')) }}</strong>
      </template>

      <template #foot(show)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('show')) }}</strong>
      </template>

      <template #foot(articles)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalArticles) }}</strong>
      </template>

      <template #foot(amount)="data">
        <strong :data-label="data.label">{{ currency(accountCurrency, totalAmount) }}</strong>
      </template>

      <template #foot(exchange)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalExchange) }}</strong>
      </template>
      <template #foot(advertising)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalAdvertising) }}</strong>
      </template>
      <template #foot(ctr)="data">
        <strong :data-label="data.label">{{ totalFormat('show') ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(totalFormat('click') / totalFormat('show') * 100) : 0 }}%</strong>
      </template>
      <template #foot(ecpm)="data">
        <strong :data-label="data.label">{{ totalFormat('show') ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 2 }).format(totalAmount / totalFormat('show') * 1000) : 0 }}</strong>
      </template>
    </b-table>

  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import {
  BTable,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAlert,
    BSpinner,
  },
  props: {
    statsData: {
      type: Array,
      required: true,
    },
    accountCurrency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      fields: [
        { key: 'datetime', label: 'Date', tdClass: 'td-width' },
        { key: 'show', label: 'Impressions', tdClass: 'td-width' },
        { key: 'click', label: 'Clicks', tdClass: 'td-width' },
        { key: 'articles', label: 'My articles', tdClass: 'td-width' },
        { key: 'exchange', label: 'Exchange', tdClass: 'td-width' },
        { key: 'advertising', label: 'Advertising', tdClass: 'td-width' },
        { key: 'ctr', label: 'CTR', tdClass: 'td-width' },
        { key: 'ecpm', label: 'eCPM', tdClass: 'td-width' },
        { key: 'amount', label: 'Income', tdClass: 'td-width' },
      ],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      statsDay: {},
      loading: false,
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.statsData.forEach(i => {
        totalAmount += i.amount
      })
      return totalAmount
    },
    totalArticles() {
      let totalArticles = 0
      this.statsData.forEach(i => {
        totalArticles += i.stats.default.article
      })
      return totalArticles
    },
    totalExchange() {
      let total = 0
      this.statsData.forEach(i => {
        total += i.stats.default.exchange
      })
      return total
    },
    totalAdvertising() {
      let total = 0
      this.statsData.forEach(i => {
        total += i.stats.default.creative
      })
      return total
    },
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.statsData.forEach(i => {
        total += i[field]
      })
      return total
    },
    showDetails(row) {
      row.toggleDetails()
      if (row.item._showDetails) {
        if (!this.statsDay[row.item.datetime]) {
          this.getStatsDay(row.item.datetime)
        }
      }
    },
    async getStatsDay(day) {
      this.loading = true
      this.statsDay[day] = null
      const params = {
        site_id: this.$route.params.siteId,
        type: 'day',
        date: day,
      }

      const responseData = await useJwt.getSiteStatsByType(params)
      this.statsDay[day] = responseData.data.sitestats || null
      this.loading = false
    },
  },
}
</script>
<style>
[dir] .table-responsive .table-responsive {
  margin-bottom: 0!important;
}

[dir] .table.b-table > tbody > tr.b-table-details > td {
  padding-left: 0!important;
  padding-right: 0!important;
  padding-bottom: 0!important;
  border-bottom: 1px solid #776bf0
}

[dir] .table.b-table > tbody > tr.b-table-details .table-striped tbody tr:nth-of-type(odd) {
  background: #ebebeb;
}

[dir] .table.b-table > tbody td.td-width {
  width: 11%;
}
</style>
